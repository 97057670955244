import { render, staticRenderFns } from "./mTabs.vue?vue&type=template&id=ee40634c"
import script from "./mTabs.vue?vue&type=script&lang=js"
export * from "./mTabs.vue?vue&type=script&lang=js"
import style0 from "./mTabs.vue?vue&type=style&index=0&id=ee40634c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports