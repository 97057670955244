<template>
  <div 
    class="m-tabs scrollable-x smooth-scroll"
    ref="m-tabs"
  >
    <v-btn-toggle
      v-model="controller.value"
      :mandatory="mandatory"
      :borderless="!outlined"
      :tile="tile"
      :active-class="'active '+color+' '+color+'--text'"
      class="m-tabs-group"
    >
      <v-btn
        v-if="controller.icon"
        :outlined="outlined"
        :height="height"
        :min-width="height*1.125"
        class="m-tabs-option option label px-0 grey--text text--lighten-2"
      >
        <v-icon 
          size="20"
          :color="color"
          class="m-tabs-icon"
          :style="{ 'color': 'var(--'+color+') !important' }"
        >
          {{ controller.icon }}
        </v-icon>
      </v-btn>
      <v-btn
        v-for="(item, index) in items"
        :key="`map-layer-${index}`"
        :ref="`item-${item.value}`"
        :value="item.value"
        :disabled="item.disabled"
        :outlined="outlined"
        :height="height"
        class="m-tabs-option button option px-3 text-overline flex-grow-1"
        :style="{ 'border-bottom-color': 'currentColor !important;' }"
        @click="select"
      >
        {{ $t(item.text) }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<style lang="scss">

  .m-tabs .m-tabs-group {
    min-width: 100%;
  }
  .m-tabs .m-tabs-group .label.v-btn--disabled .m-tabs-icon {
    opacity: 1 !important;
    cursor: default;
  }
  .m-tabs .m-tabs-group .m-tabs-option.option.label {
    border-bottom: 2px solid !important;
    background: transparent !important;
    border-bottom-color: currentColor !important;
    cursor: default;
  }
  .m-tabs .m-tabs-group .button.option.active, .m-tabs .m-tabs-group .m-tabs-option.option.label {
    border-bottom-color: currentColor !important;
    font-weight: 700;
    &:before {
      opacity: .04;
    }
  }
  .m-tabs .m-tabs-group .button.option {
    font-size: .675rem !important;
    border-bottom: 2px solid !important;
    background: transparent !important;
  }

</style>

<script>

  export default {
    props: {
      value: {
        type: String,
        default: null
      },
      items: {
        type: [Object, Array],
        default: () => null
      },
      icon: {
        type: String,
        default: null
      },
      height: {
        type: [String, Number],
        default: 40
      },
      color: {
        type: String,
        default: 'primary'
      },
      mandatory: {
        type: Boolean,
        default: false
      },
      outlined: {
        type: Boolean,
        default: false
      },
      tile: {
        type: Boolean,
        default: false
      }
    },
    
    data: () => ({
      controller: {
        value: null,
        items: [],
        icon: false
      },
    }),
    watch: {
      icon: {
        immediate: true,
        handler (icon) {
          this.controller.icon = icon;
        },
      },
      items: {
        immediate: true,
        handler (items) {
          if (items!=null) {
            this.controller.items = items.map((item, key) => {
              if (typeof item == 'string') {
                item = {
                  value: key,
                  text: item,
                  disabled: false
                }
              }
              item.disabled = _.has(item, 'disabled') ? item.disabled : false;
              return item
            });
          }else{
            console.warn('Items prop is mandatory')
          }
        },
      },
      value: {
        immediate: true,
        handler (value) {
          this.controller.value = value;
          this.scrollToItem(value);
        },
      },
    },

    methods: {
      select () {
        this.$nextTick(() => {
          console.log(this.controller.value);
          this.$emit('change', this.controller.value);
        })
      },
      scrollToItem (value) {
        this.$nextTick(() => {
          if (value!=null&&_.has(this.$refs, 'm-tabs')&&_.has(this.$refs, 'item-'+value)) {
            const view = this.$refs['m-tabs'];
            const item = this.$refs['item-'+value][0].$el;
            const scroll = item.offsetLeft+item.clientWidth>=view.clientWidth+view.scrollLeft ? 'right' : item.offsetLeft<=view.scrollLeft ? 'left' : false;
            if (scroll) {
              setTimeout((view, item, scroll) => {
                view.scrollLeft = scroll=='left' ? item.offsetLeft : (item.offsetLeft+item.clientWidth) - view.clientWidth;
              }, 250, view, item, scroll);
            }
          }
        })
      }
    },
  }
</script>